import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`Miss a Test Day this week?  Make it up today!`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will from from 11:00-11:30 Saturday, March
16th.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start next Monday, March 17th along
with our new Ville Bootcamp Tues-Friday at 9:30am!  Evening CrossFit
classes will now be offered Monday-Friday at 4:00, 5:00 & 6:00pm!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      